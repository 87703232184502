import React from 'react'
import './ShoppingCart.css'

import Cart3 from '../Cart3/Cart3';
import Cart2 from '../Cart2/Cart2';
import Cart1 from '../Cart1/Cart1';
import Table from './components/Table/table';
// import details from '../../utils/courseDetails';
import { useEffect,useState } from 'react';
const ShoppingCart = ({isCall}) => {
  const [isChange,setChange] = useState(false)
  const [details,setDetails] = useState([])
  useEffect(() =>{
   
    let items = JSON.parse(localStorage.getItem('items'));
    if(items !== null) {
      setDetails(items);
    }
    isCall((preValue) => !preValue)
    // else if(details.length > 0) {
      // let string = JSON.stringify(details) 
      // localStorage.setItem("items", string) 
      // // isCall((preValue) => !preValue)
    // }
   },[isChange])
 
  return (

    <div className='cart-container'>
    <Cart1 />
    <Cart2 isChange={isChange} items={details}/>
    {/* <Cart3/> */}
    <Table details={details} setDetails={setDetails}   setChange={setChange}/>
    </div>
  )
}

export default ShoppingCart
