import React from 'react'
import './Footer.css'



const Footer = () => {

  return (

    <div  className='footer'>


        <div className='footercontainer-1'>

        <div className="footer-1">
            <p>Title</p>
            <p>some text</p>
            <p>some text</p>
            <p>some text</p>
        </div>

        <div className=" footer-2">
            <p>Title</p>
            <p>some text</p>
            <p>some text</p>
            <p>some text</p>
        </div>

        <div className="footer-3">
            <p>Title</p>
            <p>some text</p>
            <p>some text</p>
            <p>some text</p>
        </div>
        </div>

      <div className=" footercontainer-2">
                  <p>Copyright &copy; Zeitner | v202768497.253675589</p>
                  <h3>T&Cs |  Imprint</h3>

      </div>


    </div>
  )
}

export default Footer
