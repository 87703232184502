import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Title from "./components/Title/Title";
import Academy from "./components/Academy/Academy";
import DigitalTransformation from "./components/DigitalTransformation/DigitalTransformation";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import AcademyPage from "./components/AcademyPage/AcademyPage";
import Home from "./components/Home/home";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CourseTable from "./components/CourseTable/table";
import ContactPage from "./components/ContactPage/ContactPage"
import ShoppingCart from "./components/ShoppingCart/ShoppingCart"
import DigitalTransformationPage from "./components/DigitalTransformationPage/DigitalTransformationPage";
function App() {
  const [isChange,setChange] = useState(false)

  //   return (
  // <>
  // < Navbar/>
  // <div className="carousel-container">
  // <Title/>
  // </div>
  // <Academy/>
  // <DigitalTransformation/>
  // <Contact/>
  // <Footer/>
  // <AcademyPage/>
  // </>
  //   );
  return (
    <div>
      <Router>
        <Navbar isChange ={isChange}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/academy" element={<AcademyPage setChange={setChange} />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/digital-transformation" element={<DigitalTransformationPage />} />
          <Route path="/shopping-cart" element={<ShoppingCart isCall={setChange} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
