 const details =
[
    {
        "topicId" : 1,
        "topicName" : "Data Base",
        "topicDescription" : "Hier kommt kurze Beschreibung",
        "courses" : [
            {
                "courseId" : 1,
                "courseName" : "SQL-Grundlagen",
                "courseDesc" : "Verständnis von Datenbanken, Tabellen, Abfragen und grundlegenden SQL-Befehlen.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 3550.00,
                "duration" : 5
            },
            {
                "courseId" : 2,
                "courseName" : "Datenbankdesign",
                "courseDesc" : "Normalisierung, Indexierung und Beziehungsmodellierung.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 2750.00,
                "duration" : 6
            },
            {
                "courseId" : 3,
                "courseName" : "NoSQL-Datenbanken",
                "courseDesc" : "Einführung in NoSQL-Datenbanken wie MongoDB, Cassandra usw.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 2500.00,
                "duration" : 4
            }
        ]
    },
    {
        "topicId" : 2,
        "topicName" : "Programmierung",
        "topicDescription" : "Hier kommt kurze Beschreibung",
        "courses" : [
            {
                "courseId" : 4,
                "courseName" : "Java",
                "courseDesc" : "xxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 3750.00,
                "duration" : 6
            },
            {
                "courseId" : 5,
                "courseName" : "Python",
                "courseDesc" : "xxxxxxxxxxxxxxxxxxxxxxxxx",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 3250.00,
                "duration" : 6
            },
            {
                "courseId" : 6,
                "courseName" : "C++",
                "courseDesc" : "xxxxxxxxxxxxxxxxxxx",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 2750.00,
                "duration" : 5
            },
            {
                "courseId" : 7,
                "courseName" : "JavaScript",
                "courseDesc" : "xxxxxxxxxxxxxxxxxxx",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 2000.00,
                "duration" : 5
            },
            {
                "courseId" : 8,
                "courseName" : "Algorithmen und Datenstrukturen",
                "courseDesc" : "Grundlagen von Algorithmen, Sortieralgorithmen, Suchalgorithmen usw.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 2900.00,
                "duration" : 6
            },
            {
                "courseId" : 9,
                "courseName" : "Objektorientierte Programmierung (OOP)",
                "courseDesc" : "Konzepte wie Klassen, Vererbung, Polymorphismus usw.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 3300.00,
                "duration" : 6
            }
        ]
    },
    {
        "topicId" : 3,
        "topicName" : "Webentwicklung",
        "topicDescription" : "Hier kommt kurze Beschreibung",
        "courses" : [
            {
                "courseId" : 10,
                "courseName" : "HTML, CSS und JavaScript",
                "courseDesc" : "Grundlagen für die Erstellung von Webseiten und interaktiven Benutzeroberflächen.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 3200.00,
                "duration" : 7
            },  
            {
                "courseId" : 11,
                "courseName" : "Frameworks und Bibliotheken",
                "courseDesc" : "Verwendung von Frameworks wie React, Angular, Vue.js usw.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 2350.00,
                "duration" : 7
            }          
        ]
    },
    {
        "topicId" : 4,
        "topicName" : "Cloud-Computing",
        "topicDescription" : "Hier kommt kurze Beschreibung",
        "courses" : [
            {
                "courseId" : 12,
                "courseName" : "Amazon Web Services (AWS) oder Microsoft Azure",
                "courseDesc" : "Einführung in Cloud-Dienste, Bereitstellung und Skalierung von Anwendungen.",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 4250.00,
                "duration" : 7
            }          
        ]
    },
    {
        "topicId" : 5,
        "topicName" : "Mobile App-Entwicklung",
        "topicDescription" : "Hier kommt kurze Beschreibung",
        "courses" : [
            {
                "courseId" : 13,
                "courseName" : "Android- oder iOS-Entwicklung",
                "courseDesc" : "Grundlagen der mobilen App-Entwicklung",
                "startDates"  : [
                    "2024-03-01",
                    "2024-05-01",
                    "2024-07-01",
                    "2024-10-01"
                ],
                "lecturer" :  {
                    "id" : 1,
                    "firstName" : "First Name",
                    "lastName" : "Last Name",
                    "photo"     : "images/persons/person.jpg"
                },
                "pricePerParticipant"  : 4000.00,
                "duration" : 5
            }          
        ]
    }    
]

export default details