import React from 'react'
import './Cart1.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import {faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {faAddressCard}from '@fortawesome/free-solid-svg-icons';
import {faAnglesRight} from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import cartminus from '../../assets/cartminus.svg'

const Cart1 = () => {
  return (

    <div className='cart-1'> 
    <div>
        <FontAwesomeIcon icon={faAnglesLeft} className='angles-left' />
   </div>

   <div className='cart-1-mid'>
         <hr className="basic-line" />
         <FontAwesomeIcon icon={faGraduationCap} className='midicons'/>
         <hr className="basic-line" />
         <FontAwesomeIcon icon={faAddressCard} className='midicons'/>
         <hr className="basic-line" />
         <FontAwesomeIcon icon={faMoneyCheck}className='midicons' />
         <hr className="basic-line" />
         <FontAwesomeIcon icon={faCartShopping} className='midicons' />
         <hr className="basic-line" />
   </div>
            
   <div>
         <FontAwesomeIcon icon={faAnglesRight} className='angles-right' />
    </div>

</div>
  )
}

export default Cart1
