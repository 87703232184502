import React from 'react'
import './DigitalTransformationPage.css'
import digitalmainimage from '../../assets/dt_main_bg_theme4.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import digitalimage1 from '../../assets/dt_design_1_cut_2_theme_3.png'
import digitalimage2 from '../../assets/dt_design_1_cut_3_theme_3.png'
import digitalimage3 from '../../assets/dt_design_1_cut_4.png'
import digitalimage4 from '../../assets/dt_design_1_cut_5_theme_3.png'
import Footer from '../Footer/Footer';

const DigitalTransformationPage = () => {

  return (

    <>
    <div className='digital-transformation-container'>

        <div className='digital-transformationpage-container-1'>
            <div className='digital-image-container'>
                   <img src={ digitalmainimage }></img>
                 
                   <button className='phonenumberbutton-digital'> <FontAwesomeIcon icon={faPhone} />+49 17684876611</button>
                  
            </div>
            <div>
            <button className='downarrowbutton-digital'>courses <FontAwesomeIcon icon={faAnglesDown} className='downarrow-digital'/></button>
            </div>
    </div>

        <div className='digital-transformationpage-container-2 smallcontainer1'>

             <div className='digital-transformationpage-container-2-left'>
                <img src={digitalimage1}></img>
             </div>
             <div className='digital-transformationpage-container-2-right'>
                   <h2 className='digitalpara1'>Title for 1.Icon</h2>
                   <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.</p>
             </div> 
               
        </div>

        <div className='digital-transformationpage-container-2 smallcontainer2'>

             <div className='digital-transformationpage-container-2-left'>
                 <img src={digitalimage2}></img>
            </div>
        <div className='digital-transformationpage-container-2-right'>
              <h2 className='digitalpara2'>Title for 2.Icon</h2>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.</p>
        </div> 
  
      </div>



<div className='digital-transformationpage-container-2 smallcontainer3'>

<div className='digital-transformationpage-container-2-left'>
    <img src={digitalimage3}></img>
</div>
<div className='digital-transformationpage-container-2-right'>
 <h2 className='digitalpara3'>Title for 3.Icon</h2>
 <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.</p>
</div> 

</div>


<div className='digital-transformationpage-container-2 smallcontainer4'>
<div className='digital-transformationpage-container-2-left'>
    <img src={digitalimage4}></img>
</div>
<div className='digital-transformationpage-container-2-right'>
 <h2 className='digitalpara4'>Title for 4.Icon</h2>
 <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque velit commodi fugiat culpa nam illo animi ratione suscipit, cum id.</p>
</div> 

</div>


    </div>

</>
  )
}

export default DigitalTransformationPage
