import React from 'react'
import Title from '../Title/Title'
import Academy from '../Academy/Academy'
import DigitalTransformation from '../DigitalTransformation/DigitalTransformation'

import Contact from '../Contact/Contact'
import ServiceInfo from '../ServiceInfo/ServiceInfo'
import SoftwareEngineering from '../SoftwareEngineering/SoftwareEngineering'
const Home = () => {
  return (
    <div style={{overflowX:'hidden'}}>
      
        <div className="carousel-container">
  <Title/>
  </div>
  <Academy/>
  <DigitalTransformation/>
  <ServiceInfo/>
  <SoftwareEngineering/>
  <Contact/>

    </div>
    
  )
}

export default Home
