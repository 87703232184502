import React from 'react'
import './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const Contact = () => {

  const [showForm, setShowForm]= useState(false);

  const toggleMenu = () => {
       
    setShowForm(!showForm);
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  return (
    <div className='contact'>
      <div className='contact-1'> 
             <h2>Contact</h2>
             <FontAwesomeIcon icon={ faCaretDown}className='uparrow' onClick={toggleMenu}/> 
      </div>

        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla neque ipsa officiis necessitatibus enim autem numquam, obcaecati totam voluptates alias fugit saepe vel adipisci. Excepturi.</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque voluptatibus architecto accusantium. Aut quibusdam architecto iste alias temporibus vero saepe aspernatur est. Nemo, nihil laudantium?</p>


{showForm && (<div className='formcontainer'>
      <form>
  
         <input type="text" id="fname" placeholder='First Name*' required></input>
         <input type="text" id="fname" placeholder='Last Name*' required></input>
         <input type="text" id="fname" placeholder='E-Mail*' required></input>
         <input type="text" id="fname" placeholder='Mobile number*' required></input>
         <textarea id="message" name="message" placeholder='Message' required></textarea>

         <div className="checkbox-container">

             <div className='checkbox'>
              <input
                 type="checkbox"
                 id="allClearCheckbox"
                 checked={isChecked}
                onChange={handleCheckboxChange}
               />
               
            <label htmlFor="allClearCheckbox">All Clear</label>

            </div>


              <div>
                   <button className='t-c-btn'>T&Cs</button>
               </div>
         </div>

         <button className='btn'>Send</button>

      </form>
  
</div> 
)}







      
    </div>
   
  )
}

export default Contact
