import React from "react";
import "./AcademyPage.css";
import academypageimage from "../../assets/aca_8.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import CourseTable from "../CourseTable/table";
import details from "../../utils/courseDetails";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import GradeSharpIcon from "@mui/icons-material/GradeSharp";
import { useState, useEffect } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';


const AcademyPage = ({setChange}) => {


  const [selectCourse, setSelectCourse] = useState([]);
  useEffect(() => {
    console.log(selectCourse);
  }, [selectCourse]);

  return (

    details && (
      <div className="academypage">
        <div className="academypage-container-1">
          <div className="image-container">
            <img src={academypageimage}></img>
            <button className="academybutton">Academy</button>
            <button className="phonenumberbutton">
              {" "}
              <FontAwesomeIcon icon={faPhone} />
              +49 17684876611
            </button>
            <button className="downarrowbutton">
              courses{" "}
              <FontAwesomeIcon icon={faAnglesDown} className="downarrow" />
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div className="academypage-container-2">
            <p className="academypara">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem
              illum eaque .
            </p>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis
              accusamus tempore quo necessitatibus, fugit rem eos tenetur,
              blanditiis ipsum sequi, culpa ipsam eveniet accusantium.
              OmnisLorem, ipsum dolor sit amet consectetur adipisicing elit.
              Officiis accusamus tempore quo necessitatibus, fugit rem eos
              tenetur, blanditiis ipsum sequi.
            </p>
          </div>
         <div className="filtericonmain">
          <div onClick={() =>{

setSelectCourse([])
    }}  className="filtericon">
      {
        selectCourse.length > 0 ? <FilterListIcon /> :<FilterListOffIcon />
      }
 
    </div>
    </div>
          <div className="academypage-container-3">
            {/* <div className="box-1">
            <div className="staricon">
              <FontAwesomeIcon icon={faRegularStar} />
            </div>

            <div className="paradiv">
              <p className="container-3-para"> Databases</p>
              <p className="container-3-para-1">Here comes short description</p>
            </div>
          </div> */}
         
            {details.map((course) => (
              <div
                key={course.topicId}
                className="box-2"
                style={{
                  backgroundColor: selectCourse.includes(course.topicId)
                   ?"white"
                    :"#f5f5f5",
                
                }}
                onClick={() =>
                  setSelectCourse((preArray) => {
                    let arrCourse = [...preArray];
                    if (arrCourse.length > 0) {
                      const selected = arrCourse.filter(
                        (item) => item === course.topicId
                      );
                      if (selected.length > 0) 
                        // return arrCourse;
                      arrCourse =
                        [...arrCourse.filter(
                          (item) => item !== course.topicId
                        )]
                      
                    else 
                      arrCourse.push(course.topicId);
                    } else arrCourse.push(course.topicId);
                    return [...arrCourse];
                  })
                }
              >
                <div className="staricon" >
                  {selectCourse.includes(course.topicId) === false ? (
                    <StarBorderIcon />
                  ) : (
                    <GradeSharpIcon sx={{ color: "yellow" }} />
                  )}
                </div>
                <p className="container-3-para">{course.topicName}</p>
                <p className="container-3-para-1">{course.topicDescription}</p>
              </div>
            ))}
            {/* <div className="box-2">
            <div className="staricon">
              <FontAwesomeIcon icon={faRegularStar} />
            </div>
            <p className="container-3-para"> Programming</p>
            <p className="container-3-para-1">Here comes short description' </p>
          </div>
}
          {/* <div className="box-2">
            <div className="staricon">
              <FontAwesomeIcon icon={faRegularStar} />
            </div>
            <p className="container-3-para"> Programming</p>
            <p className="container-3-para-1">Here comes short description' </p>
          </div>

          <div className="box-3">
            <div className="staricon">
              <FontAwesomeIcon icon={faRegularStar} />
            </div>
            <p className="container-3-para"> Web development</p>
            <p className="container-3-para-1">Here comes short description</p>
          </div>

          <div className="box-4">
            <div className="staricon">
              <FontAwesomeIcon icon={faRegularStar} />
            </div>
            <p className="container-3-para"> Cloud-Computing</p>
            <p className="container-3-para-1">Here comes short description</p>
          </div>

          <div className="box-5">
            <div className="staricon">
              <FontAwesomeIcon icon={faRegularStar} />
            </div>
            <p className="container-3-para"> Mobile-App development</p>
            <p className="container-3-para-1">Here comes short description</p>
          </div> */}
          </div>

          <CourseTable details={details} selectCourse={selectCourse}  setChange={setChange} />
        </div>
      </div>
    )
  );
};

export default AcademyPage;
