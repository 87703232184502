import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import courseDetails from "../../utils/courseDetails";
import DateSelecter from "./components/dateSelecter";

import AddShoppingCartSharpIcon from "@mui/icons-material/AddShoppingCartSharp";
import ShoppingCartCheckoutSharpIcon from "@mui/icons-material/ShoppingCartCheckoutSharp";
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: "15px",
  width: "80%",
  //   maxWidth: "1000px",/ Example radius
  overflowX: "scroll", // Ensure the border radius is applied properly
  border: "1px solid #ccc", // Optional: to make the border visible
}));

function CustomTable({ details, selectCourse, setChange }) {


  return (
    <CustomTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ background: "rgb(219, 70, 219)" }}>
            <TableCell
              sx={{
                width: "60%", color: 'white', fontWeight: 'bold'
              }}
            >
              Courses
            </TableCell>
            <TableCell sx={{ width: "10px", color: 'white', fontWeight: 'bold' }}>Info</TableCell>
            <TableCell sx={{ width: "120px", textAlign: "left", color: 'white', fontWeight: 'bold' }}>
              Date
            </TableCell>
            <TableCell sx={{ width: "100px", color: 'white', fontWeight: 'bold' }}>Duration (Days)</TableCell>
            <TableCell sx={{ width: "120px", color: 'white', fontWeight: 'bold' }}>Net Price/PTP</TableCell>
            <TableCell sx={{ width: "30px", cursor: "pointer", color: 'white', fontWeight: 'bold' }}>
              <ShoppingCartCheckoutSharpIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((courseDetails) =>
            courseDetails.courses.map((course) =>
              selectCourse.length > 0
                ? selectCourse.includes(courseDetails.topicId) &&
                  CosTable({ course, setChange })
                : CosTable({ course, setChange })
            )
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}

const CosTable = ({ course, setChange }) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  return (
    <TableRow>
      <TableCell sx={{ fontSize:"18px"}} >{course.courseName}</TableCell>
      <TableCell sx={{ fontSize:"18px"}}>info</TableCell>
      <TableCell>
        <DateSelecter
          date={course.startDates}
          callBack={(date) => {
         
            course.selectDate = date;
          }}
        />
      </TableCell>
      <TableCell sx={{ textAlign: "center", fontSize:"18px" }}>{course.duration}</TableCell>

      <TableCell  sx={{ textAlign: "center", fontSize:"17px" }}>€ {numberWithCommas(course.pricePerParticipant)}.00</TableCell>
      <TableCell sx={{ cursor: "pointer" }}>
        {" "}
        <AddShoppingCartSharpIcon
          onClick={() => {
            let items = JSON.parse(localStorage.getItem("items"));
            course.places = 1;
            if (course.selectDate == undefined)
              course.selectDate =
                course.startDates[course.startDates.length - 1];
            if (items == null) {
              items = [course];
              let string = JSON.stringify(items);
              localStorage.setItem("items", string);
              setChange((preValue) => {
                return !preValue;
              });
            } else {
              if (items.map((fil) => fil.courseId).includes(course.courseId))
                alert(`You already selected ${course.courseName} course`);
              else {
                items = [course, ...items];
                let string = JSON.stringify(items);
                localStorage.setItem("items", string);
                setChange((preValue) => {
                  return !preValue;
                });
              }
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default CustomTable;
