import React from 'react'
import academyimage from '../../assets/academy_2.jpeg'
const ServiceInfo = () => {


  return (

   
    
<div className='academy-container'>

    <div className='academy'>

        <div className="academy-left">
            <img src={academyimage } alt='academyimage'></img>
        </div>

        <div className="academy-right">
            <h2>Service</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis quod tenetur vitae incidunt et delectus nam asperiores vel? Culpa distinctio voluptatibus exercitationem eligendi quia delectus deleniti aliquid illum ipsum consectetur.  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20 amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20
            </p>
        </div>
      
        </div>

        <div>
        <a href="#"class="learn-more">Learn More</a>
        </div>
    
    </div>  
  )
}

export default ServiceInfo
