import React from 'react'
import softwareimage from '../../assets/aca_9.jpeg'

const SoftwareEngineering = () => {

  return (
    <div className='digital-academy-container'>

    <div className='digital-academy'>


    <div className="digital-academy-right">
            <h2>Software Engineering</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis quod tenetur vitae incidunt et delectus nam asperiores vel? Culpa distinctio voluptatibus exercitationem eligendi quia delectus deleniti aliquid illum ipsum consectetur.  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20 amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20
            </p>
        </div>

        <div className="digital-academy-left">
            <img src={softwareimage } alt='digital-academyimage'></img>
        </div>

      
      
        </div>

       
        <a href="#"class="digital-learn-more">Learn More</a>
       
    
    </div>  
  )
}

export default SoftwareEngineering
