// import React from "react";
// import "./Academy.css";
// import academyimage from "../../assets/academy_2.jpeg";

// const Academy = () => {
//   return (
//     <div className="academy">
//       <div className="academy-left">
//         <img src={academyimage} alt="academyimage"></img>
//       </div>

//       <div className="academy-right">
//         <h2>Academy</h2>
//         <p>
//           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis
//           quod tenetur vitae incidunt et delectus nam asperiores vel? Culpa
//           distinctio voluptatibus exercitationem eligendi quia delectus deleniti
//           aliquid illum ipsum consectetur. Lorem ipsum dolor sit, amet
//           consectetur adipisicing elit. Minima culpa labore sit vero repellendus
//           ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit
//           illo porro numquam dolorum officia quis explicabo neque ipsum nulla?
//           Quae molestiae ea iure ipsa. Non praesentium repellat deleniti
//           excepturi vero, officia inventore, voluptatibus voluptate quis eos.
//           Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt illo
//           odit reprehenderit tempora repellendus quis placeat magni accusamus
//           totam explicabo quasi neque necessitatibus eligendi odio maxime,
//           facilis dolorem nam dolores cum repudiandae quod libero reiciendis!
//           Sunt atque neque explicabo consectetur eveniet sit temporibus
//           reiciendis culpa repudiandae quos voluptate, nesciunt ut fugiat
//           dolorum! Voluptatem, explicabo distinctio doloremque excepturi
//           eligendi, dolor rem incidunt aspernatur quisquam maiores odio saepe
//           animi beatae aliquid. Architecto molestias repellat ab nam iste minima
//           dolore delectus, animi sapiente eius cum maxime eos est inventore?
//           Aperiam praesentium voluptates, pariatur eos tempore libero delectus
//           sit voluptatem quisquam id magni repellat nesciunt ab itaque maiores
//           quia at reiciendis beatae veniam est dignissimos iure? Fuga nobis
//           doloremque, expedita mollitia itaque tempora quibusdam quasi!
//           Perspiciatis quod distinctio inventore similique ipsum, magnam sint
//           quaerat suscipit enim provident cupiditate, consequuntur et
//           exercitationem numquam consectetur corrupti consequatur mollitia error
//           praesentium tenetur harum repudiandae sapiente. Necessitatibus earum
//           dolor officiis cupiditate iste autem dolore, officia veniam corrupti
//           saepe doloribus doloremque eaque
//         </p>
//         <a href="#" 
//         class="learn-more"
//         >
//           Learn More
//         </a>
//       </div>
//     </div>
//   );
// };

// export default Academy;

// import React from "react";
// import "./Academy.css";
// import academyimage from "../../assets/academy_2.jpeg";

// const Academy = () => {
//   return (
//     <div 
//     className="academy"
//     >
//       <div style={{
//         display: "flex",
//         flexDirection:"column",
//         justifyContent: "space-between",
//         alignItems: "center",
//         width:"70%", 
//         // 
//         padding: "1rem",
//         backgroundColor: "#F5F5F5",
//         // marginBottom: "2rem",
//         // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//         borderRadius: "10px",
//         cursor: "pointer",
//         transition: "background-color 0.3s ease-in-out",
//       }}
//          className="academy-parent-media-css-parent"
//       >
//    <div style={{
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
 
//     backgroundColor: "#F5F5F5",
//     // gap:"10px",
//     // marginBottom: "1rem",
//     // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//     borderRadius: "10px",
//     cursor: "pointer",
//     transition: "background-color 0.3s ease-in-out",
//    }}
//    className="academy-media-css"
//    >
//       <div 
//       // className="academy-left"  
//        style={{
//         marginBottom:"auto"
//       }}
//       >
//         < img src={academyimage}
//         //  alt="academyimage" 
//          style={{
   
//           height:"550px"
//          }}
//         /> 
//       </div>

//       <div style={{
//         background:"#FFFFFF",
//         height:"550px",
//         padding:"10px"
//       }} >
//         <h2 style={{
//           height:"50px",
//           padding:"10px",
//           fontWeight: "bold",
//           color: "darkmagenta"
//         }}>Academy</h2>
//         <p style={{
//         maxHeight:"calc( 550px - 62px)",
//         overflowY:"scroll",
//         }}>
//           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis
//           quod tenetur vitae incidunt et delectus nam asperiores vel? Culpa
//           distinctio voluptatibus exercitationem eligendi quia delectus deleniti
//           aliquid illum ipsum consectetur. Lorem ipsum dolor sit, amet
//           consectetur adipisicing elit. Minima culpa labore sit vero repellendus
//           ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit
//           illo porro numquam dolorum officia quis explicabo neque ipsum nulla?
//           Quae molestiae ea iure ipsa. Non praesentium repellat deleniti
//           excepturi vero, officia inventore, voluptatibus voluptate quis eos.
//           Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt illo
//           odit reprehenderit tempora repellendus quis placeat magni accusamus
//           totam explicabo quasi neque necessitatibus eligendi odio maxime,
//           facilis dolorem nam dolores cum repudiandae quod libero reiciendis!
//           Sunt atque neque explicabo consectetur eveniet sit temporibus
//           reiciendis culpa repudiandae quos voluptate, nesciunt ut fugiat
//           dolorum! Voluptatem, explicabo distinctio doloremque excepturi
//           eligendi, dolor rem incidunt aspernatur quisquam maiores odio saepe
//           animi beatae aliquid. Architecto molestias repellat ab nam iste minima
//           dolore delectus, animi sapiente eius cum maxime eos est inventore?
//           Aperiam praesentium voluptates, pariatur eos tempore libero delectus
//           sit voluptatem quisquam id magni repellat nesciunt ab itaque maiores
//           quia at reiciendis beatae veniam est dignissimos iure? Fuga nobis
//           doloremque, expedita mollitia itaque tempora quibusdam quasi!
//           Perspiciatis quod distinctio inventore similique ipsum, magnam sint
//           quaerat suscipit enim provident cupiditate, consequuntur et
//           exercitationem numquam consectetur corrupti consequatur mollitia error
//           praesentium tenetur harum repudiandae sapiente. Necessitatibus earum
//           dolor officiis cupiditate iste autem dolore, officia veniam corrupti
//           saepe doloribus doloremque eaque
//         </p>
//         </div>
//       </div>
//       <a href="#" 
//       style={{
//         marginTop: 0,
//         marginLeft:"auto"
//       }}
//       className="learn-more"
//       >
//         Learn More
//       </a>
//       </div>
//     </div>
//   );
// };

// export default Academy;



import React from 'react'
import './Academy.css'
import academyimage from '../../assets/academy_2.jpeg'

const Academy = () => {


  return (

    
<div className='academy-container'>

    <div className='academy'>

        <div className="academy-left">
            <img src={academyimage } alt='academyimage'></img>
        </div>

        <div className="academy-right">
            <h2>Academy</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis quod tenetur vitae incidunt et delectus nam asperiores vel? Culpa distinctio voluptatibus exercitationem eligendi quia delectus deleniti aliquid illum ipsum consectetur.  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20 amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit 
            </p>
        </div>
      
        </div>

        <div>
        <a href="#"class="learn-more">Learn More</a>
        </div>
    
    </div>  
  )
}

export default Academy
