import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState ,useEffect} from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 60,
   
    },
  },
};



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DateSelecter({date,isDate,callBack,places,selectDate}) {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    callBack(value)
    isDate ? 
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    ) : setPersonName(
      // On autofill we get a stringified value.
     [ value,]
    ) 
  };
  useEffect(() =>{
    console.log(personName.length);
  },[personName])

  return (
    <div>
      <FormControl sx={{  width: isDate ? 150 : 60 }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
        //   multiple
          // value={personName.length > 0 ? personName : [date[date.length - 1]]}
          value={isDate ?selectDate :places }
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          {date.map((name) => (
            <MenuItem
              key={name}
              value={name}
              // style={getStyles(name, personName, theme)}
              sx={{
               maxWidth:"20px",
               textAlign: "center",
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
