import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import courseDetails from "../../../../utils/courseDetails";
import DateSelecter from "./components/dateSelecter";

import AddShoppingCartSharpIcon from "@mui/icons-material/AddShoppingCartSharp";
import ShoppingCartCheckoutSharpIcon from "@mui/icons-material/ShoppingCartCheckoutSharp";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "15px",
  width: "70%",
  //   maxWidth: "1000px",/ Example radius
  overflowX: "scroll", // Ensure the border radius is applied properly
  border: "1px solid #ccc", // Optional: to make the border visible
}));

function CustomTable({ details, setDetails, setChange }) {
  return (
    <CustomTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ background: "rgb(219, 70, 219)" }}>
            <TableCell
              sx={{
                width: "60%", color:'white',fontWeight:'bold'
              }}
            >
              Courses
            </TableCell>
            <TableCell sx={{ width: "120px", textAlign: "center",color:'white',fontWeight:'bold' }}>
              Date
            </TableCell>
            <TableCell sx={{ width: "15%",color:'white',fontWeight:'bold' }}>Net Price/PTP</TableCell>
            <TableCell sx={{ width: "100px",color:'white',fontWeight:'bold' }}>Place</TableCell>

            <TableCell sx={{ width: "30px", cursor: "pointer",color:'white' }}>
              <RemoveShoppingCartIcon
                onClick={() => {
                  let string = JSON.stringify([]);
                  localStorage.setItem("items", string);
                  setChange((preValue) => !preValue);
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((course) =>
            // selectCourse.length > 0
            //   ? selectCourse.includes(course.courseId) &&
            //     CosTable({ course, setChange })
            // :
            CosTable({ course, setChange, setDetails, details })
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}

const CosTable = ({ course, setChange, setDetails, details }) => {
  const count = () => {
    const arr = [];
    for (let i = 1; i < course.duration + 1; i++) {
      arr.push(i);
    }
    return arr;
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function callChangeStorage(data) {
    let string = JSON.stringify(data);
    localStorage.setItem("items", string);
    setChange((preValue) => !preValue);
  }
  return (
    <TableRow>
      <TableCell sx={{ fontSize:"17px"}}>{course.courseName}</TableCell>

      <TableCell>
        <DateSelecter
          date={course.startDates}
          selectDate={course.selectDate}
          isDate={true}
          callBack={(date) => {
            setDetails((preValue) => {
              const getPreValue = [...preValue];
              getPreValue.forEach((item) => {
                if (item.courseId === course.courseId) {
                  item.selectDate = date;
                }
              });
              callChangeStorage(getPreValue);
              return getPreValue;
            });

            course.selectDate = date;
          }}
        />
      </TableCell>
      {/* <TableCell sx={{ textAlign: "center" }}>{course.duration}</TableCell> */}

      <TableCell sx={{ fontSize:"17px"}}>€ {numberWithCommas(course.pricePerParticipant)}.00</TableCell>
      <TableCell>
        <DateSelecter
          date={count()}
          places={course.places}
          isDate={false}
          callBack={(date) => {
            setDetails((preValue) => {
              const getPreValue = [...preValue];
              getPreValue.forEach((item) => {
                if (item.courseId === course.courseId) {
                  item.places = date;
                }
              });
              callChangeStorage(getPreValue);
              return getPreValue;
            });
          }}
        />
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }}>
        {" "}
        <RemoveShoppingCartIcon
          onClick={() => {
            setDetails((preValue) => {
              const getPreValue = [...preValue];
              const getFilter = [
                ...getPreValue.filter(
                  (value) => value.courseId !== course.courseId
                ),
              ];
              callChangeStorage(getFilter);
              return getFilter;
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default CustomTable;
