import React from "react";
import "./Cart2.css";
import { useEffect, useState } from "react";
const Cart2 = ({isChange,items}) => {
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  useEffect(() => {
    // let items = JSON.parse(localStorage.getItem("items"));
    if (items !== null) {
      let sum = items.reduce(
        (acc, curr) => acc + curr.pricePerParticipant * curr.places,
        0
      );
      setPrice(sum);
      setTax((19 * sum) / 100);
      setTotalPrice(sum + (19 * sum) / 100);
    }
  },[items])
  function numberWithCommas(x) {
    if (x == 0) return x;
    return "€" + " " + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+".00"
  }
  return (
    <div className="cart-2">
      <div className="form-container">
        <div className="form-group">
          <label htmlFor="input1">totalprice (net)</label>
          <input
            id="input1"
            type="text"
            value={numberWithCommas(price)}
            placeholder="0,00 €"
          />
        </div>
        <div className="form-group">
          <label htmlFor="input2">VAT .(19%)</label>
          <input
            id="input2"
            type="text"
            value={numberWithCommas(tax)}
            placeholder="0,00 €"
          />
        </div>
        <div className="form-group">
          <label htmlFor="input3">totalprice (large)</label>
          <input
            id="input3"
            type="text"
            value={numberWithCommas(totalprice)}
            placeholder="0,00 €"
          />
        </div>
      </div>
    </div>
  );
};

export default Cart2;
