import React from 'react'
import './DigitalTransformation.css'
import academyimage from '../../assets/academy_2.jpeg'


const DigitalTransformation = () => {



  return (

    <div className='digital-academy-container'>

    <div className='digital-academy'>


    <div className="digital-academy-right">
            <h2>Digital Transformation</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis quod tenetur vitae incidunt et delectus nam asperiores vel? Culpa distinctio voluptatibus exercitationem eligendi quia delectus deleniti aliquid illum ipsum consectetur.  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20 amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20  amet consectetur adipisicing elit. Minima culpa labore sit vero repellendus ut voluptas totam sequi necessitatibus iste fugit aliquid eveniet odit illo porro numquam dolorum officia quis explicabo neque ipsum nulla? Quae molestiae ea iure ipsa. lorem20
            </p>
        </div>

        <div className="digital-academy-left">
            <img src={academyimage } alt='digital-academyimage'></img>
        </div>

      
      
        </div>

       
        <a href="#"class="digital-learn-more">Learn More</a>
       
    
    </div>  
  )
}





export default DigitalTransformation
