import React from 'react'
import './ContactPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import contactimage from '../../assets/abst_01.png'
import Contact from '../Contact/Contact';

const ContactPage = () => {

  return (

<>
<div className="academypage">
    <div className='academypage-container-1'>
            <div className='image-container'>
                   <img src={contactimage }></img>
                   <button className='contact-academybutton'>Contact</button>
                   <button className='contact-phonenumberbutton'> <FontAwesomeIcon icon={faPhone} />+49 17684876611</button>
                   <button className='contact-downarrowbutton'>Send us a message <FontAwesomeIcon icon={faAnglesDown} className='downarrow'/></button>
            </div>
        </div>

        <div className='contact-academypage-container-2'>
            <p className='academypara'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem illum eaque .</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis accusamus tempore quo necessitatibus, fugit rem eos tenetur, blanditiis ipsum sequi, culpa ipsam eveniet accusantium. OmnisLorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis accusamus tempore quo necessitatibus, fugit rem eos tenetur, blanditiis ipsum sequi.</p>
        </div>

<Contact/>
</div>
        </>
  )
}

export default ContactPage
