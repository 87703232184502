import React from 'react'
import './Title.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../assets/c_1.png'
import image2 from '../../assets/c_2.png'
import image3 from '../../assets/c_3.png'
import image4 from '../../assets/c_4.png'
import image5 from '../../assets/c_5.png'
import image6 from '../../assets/c_6.png'
import image7 from '../../assets/c_7.png'
import image8 from '../../assets/c_8.png'
import image9 from '../../assets/c_9.png'
import image10 from '../../assets/c_10.png'
import image11 from '../../assets/c_11.png'
import image12 from '../../assets/c_12.png'


const Title = () => {

    const images = [
        image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12
    ];

  return (
    <Carousel
    showThumbs={false}
    showStatus={false}
    infiniteLoop
    useKeyboardArrows
    autoPlay
    interval={3000}
>
    {images.map((src, index) => (
        <div key={index} className="carousel-image-container">
            <img src={src} alt={`carousel-img-${index}`} className="carousel-image"/>
        </div>
    ))}
</Carousel>
);
};

export default Title
