import React from 'react'
import './Navbar.css'
import logo from '../../assets/zg_home_logo_solid.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState,useEffect } from 'react';
import {useNavigate} from "react-router-dom"
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';


const Navbar = ({isChange}) => {


  const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [cartCount,setCount] = useState(0)

    const toggleMenu = () => {
   
     setIsMenuOpen(!isMenuOpen);
   };

   useEffect(() =>{

    let items = JSON.parse(localStorage.getItem('items'));
    if(items !== null) {
      setCount(items.length);
    }
   },[isChange])



  return (
   <nav >
      <img src={logo} alt='logo' className='logo' onClick={() => navigate('/')} ></img>
      <ul className={`nav-links-mob ${isMenuOpen ? 'nav-links-open' : 'nav-links-close'}`}>
        <li className='listitems listitems-0' onClick={() => navigate('/')}> Home</li>
        <li className='listitems listitems-1' onClick={() => navigate('/academy')}> Academy</li>
        <li  className='listitems listitems-2' onClick={() => navigate("/digital-transformation")} > Digital Transformation</li>
        <li  className='listitems listitems-3'> Service</li>
        <li  className='listitems listitems-4'> Software Engineering</li>
        <li  className='listitems listitems-5' onClick={() => navigate("/contact")}> Contact</li>

       

      </ul>

<div className='nav-cart'>
      <div style={{display:"inline-block",textAlign:"center",color:"black"}}  >
        <ShoppingCartSharpIcon sx={{width:'50px',objectFit:"contain"}} onClick={() => navigate("/shopping-cart")} />
        <li style={{display:"inline-block",textDecoration:"none"}} >{cartCount}</li>
        </div>

 </div>
    
      <div className='icon'>
      {isMenuOpen? <FontAwesomeIcon icon={faTimes} className='crossmark-icon' onClick={toggleMenu}/> : <FontAwesomeIcon icon={faBars}  className='hamburger-icon'onClick={toggleMenu} /> }
      </div>

   </nav>
  )
}

export default Navbar
